//
// VARIABLES
// --------------------------------------------------

$miw-sm:				576px
$miw-md:				768px
$miw-lg:				992px
$miw-xl:				1200px
$miw-ex:				1300px

$maw:					575px
$maw-sm:				767px
$maw-md:				991px
$maw-lg:				1199px
$maw-xl: 				1299px

$bgFirstColor: #850909
$bgSecondColor: #110000

$primary:				#ac0000
$primary-hover:			lighten($primary,5%)
$secondary:             #a6a1a1
$secondary-hover:		lighten($secondary,5%)
$success:				#849324
$success-hover:			darken($success,5%)
$success-light:			lighten($success,45%)
$info:					#5C80BC
$info-hover:			darken($info,5%)
$info-light:			lighten($info,35%)
$warning:				#ED9B40
$warning-hover:			darken($warning,5%)
$warning-light:			lighten($warning,30%)
$danger:				#F72C25
$danger-hover:			darken($danger,5%)
$danger-light:			lighten($danger,40%)
$light:					#F1F5E6
$light-hover:			darken($light,15%)
$dark:					#34252F
$dark-hover:			lighten($dark,5%)

/* other colors */
$color-1:				#919191

/* base setting */
$font-family-base:		'Roboto', sans-serif
$font-family-sec:		'Arial', sans-serif
$body-color:			$dark			// base font color
$border-radius: 		rem(4)
$container-max-widths: 	(xl: 1170px)
$embed-responsive-aspect-ratios: ((3 4),(4 3),(1 1))

/* links */
$link-color:			$primary
$link-hover-color:		$primary

/* headings */
$font-weight-light: 	300
$font-weight-normal:	400
$font-weight-medium:	600
$font-weight-bold:  	700
$font-weight-black:  	900
$headings-margin-bottom: rem(24)
$headings-font-family:  $font-family-base
$headings-font-weight:  $font-weight-bold
$headings-color:		$dark

$h1-font-size:			rem(40)
$h2-font-size:			rem(28)
$h3-font-size:			rem(24)
$h4-font-size:			rem(21)
$h5-font-size:			rem(18)
$h5-font-size:			rem(16)

/* buttons */
$btn-box-shadow:		none
$btn-border-radius: 	rem(4)
$btn-border-width: 		0
$btn-font-weight: 		$font-weight-normal
$btn-padding-x-sm: 		rem(10)
$btn-padding-y-sm: 		rem(4)
$btn-font-size: 		rem(16)
$btn-padding-x: 		rem(15)
$btn-padding-y: 		rem(7)
$btn-font-size-lg: 		rem(16)
$btn-padding-x-lg: 		rem(22)
$btn-padding-y-lg: 		rem(12)

/* forms */
$input-color:               $body-color
$input-border-width:        2px
$input-border-color:        $light
$input-focus-border-color: 	$light
$input-btn-focus-color:     $light-hover
$input-font-weight: 		$font-weight-normal
$input-font-size: 			rem(16)
$input-btn-padding-y:   	rem(5)
$input-btn-padding-y-lg:   	rem(11)
$input-font-size-lg:        rem(16)
$form-group-margin-bottom: 	rem(15)
$input-placeholder-color: 	$color-1

$custom-control-indicator-size: 				rem(24)
$custom-control-indicator-bg: 					#fff
$custom-control-indicator-checked-bg:           #fff
$custom-control-indicator-checked-border-color: $light
$custom-control-indicator-border-color:         $light
$custom-control-indicator-checked-color: 		$dark
$custom-control-indicator-box-shadow:           none
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 14'><path fill='#{$custom-control-indicator-checked-color}' d='M182.718,527.066,174.31,535.6l-1.579,1.6a1.111,1.111,0,0,1-1.579,0l-1.579-1.6-4.2-4.266a1.151,1.151,0,0,1,0-1.6l1.579-1.6a1.109,1.109,0,0,1,1.58,0l3.414,3.476,7.618-7.743a1.111,1.111,0,0,1,1.579,0l1.579,1.6a1.149,1.149,0,0,1,0,1.6Z' transform='translate(-165.043 -523.531)'/></svg>")
$custom-control-indicator-bg-size:              rem(14) rem(14)
$custom-control-gutter:                         rem(10)

/* tables */
$c-border: 					$color-1 // color borders
$table-hover-bg:            $light
$table-cell-padding:        rem(15)
$table-border-color:        $c-border

/* breadcrumb */
$breadcrumb-bg:				transparent
$breadcrumb-padding-x:		0
$breadcrumb-padding-y:		0
$breadcrumb-item-padding: 	rem(10)
$breadcrumb-margin-bottom:  rem(15)
$breadcrumb-divider-color:  #000
$breadcrumb-active-color: 	#000
$breadcrumb-divider: 		'/'
$breadcrumb-font-size:      rem(14)

/* alert */
$alert-padding-x: 		rem(15)
$alert-border-width: 	0
$alert-margin-bottom: 	rem(15)
$alert-border-width: 	0
$alert-bg-level: 		0

/* tabs */
$nav-link-padding-y:				rem(9)
$nav-link-padding-x:				rem(20)
$nav-tabs-border-color:				transparent
$nav-tabs-link-hover-border-color:	transparent
$nav-tabs-link-active-color: 		#000
$nav-tabs-link-active-border-color: transparent
$nav-tabs-link-active-bg:           transparent
$nav-tabs-border-radius:            0

/* card */
$card-bg:                   transparent

/* modal */
$modal-inner-padding:       rem(20)
$modal-header-border-width: 0
$modal-footer-border-width: 1px
$modal-content-border-width: 0
$modal-footer-border-color: $color-1
$modal-content-inner-border-radius: 0
$modal-lg:                  rem(970)

/* close */
$close-font-size:       rem(30)
$close-font-weight:     $font-weight-normal

/* dropdown */
$dropdown-padding-y:        0
$dropdown-font-size:        rem(14)
$dropdown-divider-bg:       $light
$dropdown-item-padding-y:   rem(10)
$dropdown-item-padding-x:   rem(15)
$dropdown-border-width:     rem(2)
$dropdown-border-color:     $light
$dropdown-box-shadow:       none
$dropdown-link-hover-bg:    $light

/* ----- BOOTSTRAP ----- */

/* container */
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1160px, ex: 1260px)

/* spacer for bootstrap - e.g. .mb-1 (margin-bottom: 15px) */
$spacer: 1rem     // 15px                 25px                    30px                  40px                45px
$spacers: (0: 0, 1: ($spacer * .9375), 2: ($spacer * 1.5625), 3: ($spacer * 1.875), 4: ($spacer * 2.5), 5: ($spacer * 2.8125))

/* bootstrap rounded - false */
$enable-rounded:    	true
$enable-shadows:    	true
$enable-gradients:    	false

$color-2:				#d8d8d8