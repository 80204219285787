/* ----------------- FUNCTIONS ---------------------- */

@use "sass:math"

@function rem($pixels, $context: 16)
  @if (unitless($pixels))
    $pixels: $pixels * 1px
  @if (unitless($context))
    $context: $context * 1px
  @return math.div($pixels, $context) * 1rem

/* ------------------ BOOTSTRAP -------------------- */

@import 'variables'
@import '~bootstrap/scss/bootstrap'

/* ------------------ CUSTOM -------------------- */

.header
  //background-color: #1c1c1c
  background: linear-gradient(to right, $bgFirstColor, $bgSecondColor)
  //background-image: URL('../images/top_panel.png')
  ////background-size: cover
  //background-repeat: no-repeat
  //background-position: center
  //border-bottom: 2px solid rgb(255,255,255)
  //box-sizing: content-box

  .navbar
    //background: rgba(255,0,0,0.4)
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ff000000,endColorstr=#ff000000)
    zoom: 1

    @media only screen and (max-width: $maw-lg)
      .nav-link
        padding-left: 0
        padding-right: 0

    @media only screen and (max-width: 668px)
      //.navbar-toggler
      //  order: 2 !important
      //
      //.navbar-brand__info
      //  order: 3 !important

    .navbar-brand
      h1
        color: white
        font-size: 1.5rem
        font-weight: 700
        margin: 0
        padding: 0

      img
        width: 160px

      &__link
        color: #dedede
        font-size: 1.0rem
        text-decoration: none

      &__contact
        margin-top: 5px

      &__socials
        div
          margin-left: 8px

      @media only screen and (max-width: $maw-lg)
        h1
          font-size: 1.2rem

        &__link
          font-size: 0.955rem

      @media only screen and (max-width: $maw-md)
        margin-right: 5px

        h1
          font-size: 1.5rem
          text-align: center

        //&__socials
        //  justify-content: center

      @media only screen and (max-width: $maw-sm)
        margin-right: 5px

        h1
          font-size: 1.1rem

      @media only screen and (max-width: 668px)
        img
          width: 90px

        &__socials
          div
            margin-left: 2px

      @media only screen and (max-width: $maw)
        img
          width: 100px

        //h1
        //  text-align: center

        //img
        //  display: none

        //&__info
          //width: 100%
          //justify-content: center

        &__socials
          margin-left: 10px
          div
            margin: 0 2px

      @media only screen and (max-width: 458px)
        img
          width: 70px
          margin-right: 10px

        h1
          font-size: 1.1rem

        //&__link
        //  font-size: 0.7rem

    .nav-link
      color: white

.carousel
  .carousel-caption
    top: 30%
    bottom: auto

    h3
      color: white
      font-size: 4.5rem
      font-weight: 900
      margin-bottom: 0
      padding: 0
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5)

    p
      color: white
      font-size: 2.5rem
      font-weight: 700
      margin-bottom: 0
      padding: 0
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5)

  .carousel-socials
    position: absolute
    bottom: 40px
    right: 10px
    z-index: 1000

  @keyframes float_from_left
    0%
      transform: translate(-200px, 0)
    50%
      transform: translate(+50px, 0)
    100%
      transform: translate(0px, 0)

  @keyframes float_from_right
    0%
      transform: translate(200px,  0px)
    50%
      transform: translate(-50px, 7px)
    100%
      transform: translate(0px, -0px)

  .carousel-contact
    background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.001))
    position: absolute
    top: 0
    left: 0
    padding-top: 20px
    padding-bottom: 40px
    padding-left: 60px
    width: 100%

    li
      animation-duration: 3s
      animation-iteration-count: 1
      animation-timing-function: ease-in-out
      animation-name: float_from_right

    li:nth-child(odd)
      animation-name: float_from_left

    @media only screen and (max-width: 390px)
      padding-top: 10px
      padding-left: 10px

    a
      color: white
      font-size: 1.5rem
      text-decoration: none
      span
        color: #d20c0c !important

.bg-content-red
  @media only screen and (max-width: $maw-sm)
    background: $bgFirstColor
    .card
      color: white

.bg-footer
  background: linear-gradient(to right, $bgFirstColor, $bgSecondColor)
  color: white

  h1, h2, h3, h4, h5, h6
    color: #e3e3e3

  a
    color: #e3e3e3

#hp_carousel .carousel-item img
  object-fit: cover
  object-position: center
  max-height: 60vh
  overflow: hidden

.content
  @media only screen and (max-width: $maw)
    img
      max-width: 100%
      height: auto
    table
      max-width: 100%

#eu-cookies
  position: fixed
  bottom: 10px
  right: 10px
  width: 600px
  z-index: 999
  border: 2px solid #000
  border-radius: 0
  display: none

  @media only screen and (max-width: $maw)
    width: 100%
    bottom: 0
    right: 0
